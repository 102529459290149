<template>
    <div class="select">
        <div class="table-container">
            <div class="button_container">
                学期：
                <el-select size="small" v-model="semesterId" placeholder="请选择" class="select_container input"
                           @change="onSemester">
                    <el-option
                        v-for="item in this.$store.state.chinese.semester.list"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                    </el-option>
                </el-select>
                教材：
                <el-select size="small" v-model="bookId" placeholder="请选择" class="select_container input">
                    <el-option
                        v-for="item in this.$store.state.chinese.book.list"
                        :key="item.id"
                        :label="item.publishing"
                        :value="item.id">
                    </el-option>
                </el-select>
                单元名称：
                <el-input size="small" placeholder="请选择" class="school_input select_container input"
                          v-model="unitName" style="width: 150px"></el-input>
                章节名称：
                <el-input size="small" placeholder="请选择" class="school_input select_container input"
                          v-model="chapterName" style="width: 150px"></el-input>
                <el-button size="small" type="primary" class="select_container" @click="onSearch">查询
                </el-button>
                <el-button size="small" type="primary" class="select_container" @click="onReset">重置
                </el-button>
                <el-button size="small" type="primary" class="select_container" @click="addChapter" v-if="hasAddBook">添加章节
                </el-button>
            </div>
            <el-table border :data="this.$store.state.chinese.chapter.list">
                <el-table-column type="index" :index="indexCompute" label="序号" align="center"
                                 width="60px"></el-table-column>
                <el-table-column prop="semesterName" label="学期" align="center" width="100px"></el-table-column>
                <el-table-column prop="publishing" label="教材名称" align="center" min-width="100px"></el-table-column>
                <el-table-column prop="unitName" label="单元名称" align="center" min-width="100px"></el-table-column>
                <el-table-column prop="name" label="章节名称" align="center" min-width="100px"></el-table-column>
                <el-table-column label="操作" align="center" width="400px">
                    <template slot-scope="scope">
                        <el-link type="primary" icon="el-icon-delete" @click="deleteChapter(scope.row)"
                                 v-if="hasDeleteBook">删除
                        </el-link>&nbsp;&nbsp;&nbsp;
                        <el-link type="primary" icon="el-icon-edit" @click="updateChapter(scope.row)" style="margin-left: 10px;"
                                 v-if="hasPutBook">修改
                        </el-link>&nbsp;&nbsp;&nbsp;
                        <el-link type="primary" icon="el-icon-right" @click="goword(scope.row)" style="margin-left: 10px;">前往该教材字词</el-link>&nbsp;&nbsp;&nbsp;
                        <el-link type="primary" icon="el-icon-s-data" @click="errWord(scope.row)" style="margin-left: 10px;">该章节易出错词</el-link>
                    </template>
                </el-table-column>
            </el-table>

        </div>
        <div class="pagination-container">
            <el-pagination class="pagination afterClearfix"
                           @size-change="handleSizeChange"
                           @current-change="handleCurrentChange"
                           :current-page="pagination.page+1"
                           :page-sizes="[10, 20, 30, 50]"
                           :page-size="pagination.size"
                           layout="total, sizes, prev, pager, next, jumper"
                           :total="this.$store.state.chinese.chapter.total"
                           style="float: right">
            </el-pagination>
        </div>
        <router-view/>
        <el-dialog
            title="错词统计"
            :visible.sync="dialogVisible"
            width="500px">
            <ve-histogram :data="chartData4" :settings="chartSettings4"></ve-histogram>
        </el-dialog>
    </div>
</template>

<script>
    // import {getDateFromTimeStamp} from '@/libs/tools'
    import {checkPermission, getData, setData} from "@/utils/cookies";
    // import md5 from 'js-md5';
    // import {compressFile, isFloat} from "@/utils/tools";

    export default {
        name: 'List',
        data() {
            return {
                semesterId: '',//学期id
                bookId: '',//教材id
                unitName: '',//单元名称
                chapterName: '',//章节名称
                pagination: {
                    page: 0,
                    size: 10,
                },
                dialogVisible: false,//错词弹框
                hasAddBook: checkPermission("addBook"),
                hasPutBook: checkPermission("putBook"),//修改教材权限
                hasDeleteBook: checkPermission("deleteBook"),//删除教材的权限
                chartSettings4: {
                    metrics: ['错词前五'],
                    dimension: ['错词']
                },

            }
        },
        computed: {
            // 教材分布统计
            chartData4() {
                let rows = this.$store.state.chinese.data16.map((item) => {
                    let row = {};
                    row['错词'] = item.name;
                    row['错词前五'] = item.count;
                    return row;
                })
                let columns = ['错词', '错词前五'];
                return {rows, columns}
            },
        },
        mounted() {
            this.semesterId = getData('goChapterInfo') && getData('goChapterInfo').semesterId;
            this.bookId = getData('goChapterInfo') && getData('goChapterInfo').bookId;
            this.semesterList();
            this.getBookList();
            this.getChapterList();
        },
        methods: {
            // 获取学期列表
            semesterList() {
                this.$store.dispatch('chinese/semesterList')
            },

            // 获取教材列表
            getBookList() {
                if (this.semesterId) {
                    this.$store.dispatch("chinese/bookList", {'semesterId': this.semesterId, "size": 999});
                }
            },

            // 获取章节列表
            getChapterList() {
                this.$store.dispatch("chinese/chapterList", {
                    'semesterId': this.semesterId,
                    'bookId': this.bookId,
                    'unitName': this.unitName,
                    'name': this.chapterName,
                    'page': this.pagination.page,
                    'size': this.pagination.size
                });
            },

            // 查询
            onSearch() {
                this.pagination.page = 0;
                this.getChapterList()
            },

            // 重置
            onReset() {
                this.semesterId = undefined;
                this.bookId = undefined;
                this.unitName = undefined;
                this.chapterName = undefined;
                this.pagination.page = 0;
                this.getChapterList();
            },

            // 添加章节
            addChapter() {
                this.$router.push('/chinese/chapter/add');
            },

            // 删除单元
            deleteChapter(row) {
                this.$store.dispatch("chinese/deleteChapter", {
                    'id': row.id,
                }).then((data) => {
                    this.getChapterList();
                });
            },

            // 修改教材
            updateChapter(row) {
                setData('chapterInfo', row)
                this.$router.push('/chinese/chapter/update');
            },

            // 前往该字词
            goword(row) {
                setData('wordInfo', row)
                this.$router.push('/chinese/word/word');
            },

            //章节错词
            errWord(row) {
                console.log(row);
                this.dialogVisible = true;
                this.$store.dispatch("chinese/getData16", {
                    chapterId: row.id
                });
            },

            // 列表序号
            indexCompute(index) {
                return index + 1 + this.pagination.page * this.pagination.size;
            },

            // 页大小改变
            handleSizeChange(size) {
                this.pagination.size = size;
                this.getChapterList();
            },

            // 页码改变
            handleCurrentChange(page) {
                this.pagination.page = page - 1;
                this.getChapterList();
            },

            // 学期改变
            onSemester() {
                this.getBookList();
                this.bookId = undefined;
            },
        }
    }
</script>

<style scoped lang="scss">
    .select {
        padding: 0 15px;
    }

    .button_container {
        width: 100%;
        margin-bottom: 20px;
        font-size: 14px;
        align-items: center;

        .select_container {
            margin-right: 30px;
            margin-bottom: 30px;
        }

        .input {
            width: 150px;
        }

        .school_input {
            display: inline-block;
            width: 300px;
        }

        .upload_container {
            display: inline-flex;
            width: 80px;
            height: 32px;
            position: relative;
            overflow: hidden;
            background-color: #409EFF;
            border-radius: 5px;
            color: #ffffff;
            line-height: 32px;
            justify-content: center;
            margin-right: 30px;
            font-size: 12px;
        }

        .upload_input {
            display: inline-block;
            position: absolute;
            top: 0;
            left: -500px;
            width: 580px;
            height: 32px;
            border: none;
            outline: none;
        }
    }


</style>
